.input {
    width: 250px; }

.input-section {
    margin-top: 20px; }


label {
    font-size: .8em !important;
    font-weight: normal; }
