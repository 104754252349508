.order-row__container {
  margin: .01em 0;

  ul {
    padding: 1em .5em;
    background: white;
    margin: 0;
    border-radius: .5em;
    box-shadow: 0 0 .1em #eeeeee;

    li {
      list-style: none;
      margin-right: 3em; } }

  .order-row__down-item {
    width: 20px; }


  .order-row__date {
    width: 10em; } }
